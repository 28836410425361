import React, {useState} from 'react';
import { Route, Routes } from 'react-router-dom';
import { AddForm } from './components/AddForm/AddForm';
import {Header} from "./components/layout/Header";
import { SearchContext } from './contexts/serch.context';
import {Map} from "./Map/Map";

export const App = () => {
    const [search, setSearch] = useState('');

    return (
        <SearchContext.Provider value={{search, setSearch}}>
            <Header/>
            <Routes>
                <Route path="/" element={<Map/>}/>
                <Route path="/add" element={<AddForm/>}/>
            </Routes>
        </SearchContext.Provider>
    );
};


